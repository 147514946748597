<template>
    <div class="create-shop">
        <div class="si-con">
            <a-steps :current="active">
                <a-step title="产品方案"/>
                <a-step title="程序版本"/>
                <a-step title="农场信息"/>
                <a-step title="完成"/>
            </a-steps>
            <!-- 产品方案 -->
            <ul class="si-scheme" v-if="active == 0">
                <li v-for="(item,index) in server" :key="index">
                    <div class="top">
                        <p class="title">{{ item.father_name}}</p>
                        <p class="desc">{{ item.description }}</p>
                    </div>
                    <div class="btm">
                        <!-- <p class="desc" v-if="!item.price">可免费使用</p> -->
                        <p class="desc">
                            <span> {{item.remark}} </span>
                        </p>
                        <a-button type="primary" @click="selectServer(item.id)">立即创建</a-button>
                    </div>
                </li>
            </ul>

            <!-- 程序版本 -->
            <ul class="si-server" v-if="active == 1">
                <li v-for="(item,index) in meal" :key="index">
                    <p class="title">{{ item.server_name }}</p>
                    <p class="func">{{ item.description }}</p>
                    <div class="price" v-if="item.price>0">{{item.price }}元/年</div>
                    <div class="price" v-else>免费</div>
                    <div class="now-price" v-if="item.discount_price">限时优惠：{{item.discount_price}}元/年</div>
                    <div class="now-price">次年续费：
                        {{ item.renewal_discount_price ? item.renewal_discount_price :item.renewal_price }}
                        元/年
                    </div>
                    <div class="btn">
                        <a-button type="primary" @click="selectMeal(item)">立即创建</a-button>
                    </div>
                </li>
            </ul>
            <!-- 农场信息 -->
            <div class="si-farm" v-if="active == 2">
                <a-form :label-col="{span:6}" :wrapper-col="{span:14}">
                    <a-form-item label="农场名称">
                        <a-input v-model:value="form.program_name"></a-input>
                    </a-form-item>
                    <a-form-item label="农场logo">
                        <kd-img-select @success="e=>form.logo = e"></kd-img-select>
                    </a-form-item>
                    <a-form-item :wrapper-col="{offset:6}">
                        <a-space>
                            <a-button @click="createShopNow" v-if="show.isCreate">立即创建</a-button>
                            <a-button type="primary" @click="show.payMethod = true" v-if="show.showPay">支付购买</a-button>
                        </a-space>
                    </a-form-item>
                </a-form>
            </div>

            <div class="si-complete" v-if="active == 3">
                <p>农场创建成功，<span>0{{time}}</span> 秒后自动跳转至应用列表</p>
                <a-space style="margin-top:36px;">
                    <a-button @click="toManager(-1)">立即返回</a-button>
                    <a-button type="primary" @click="toManager(1)">进入农场管理</a-button>
                </a-space>
            </div>
        </div>
        <a-modal v-model:visible="show.payMethod" title="选择支付方式" width="700px" @ok="createShopOrder">
			<a-form :label-col="{span:6}" :wrapper-col="{span:14}">
                <a-form-item label="选择支付方式">
                    <div class="kd-paymethod">
						<div class="kd-paymethod-li" :class="[form.pay_type=='al'?'kd-p-active':'']" @click="form.pay_type='ali'">
							<i class="ri-alipay-line ri-top ri-lg"  style="color: #2196F3;"></i>支付宝支付
							<img v-if="form.pay_type=='ali'" class="active-img-alipay" src="@/assets/img/icon-share-selected.png" alt="">
						</div>
						<div class="kd-paymethod-li" :class="[form.pay_type=='wx'?'kd-p-active':'']" @click="form.pay_type='wx'">
							<i class="ri-wechat-pay-line ri-top ri-lg" style="color: #4CAF50;"></i> 微信支付
							<img v-if="form.pay_type=='wx'" class="active-img" src="@/assets/img/icon-share-selected.png" alt="">
						</div>
					</div>
                </a-form-item>
            </a-form>
		</a-modal>

        <!-- 微信支付二维码弹框 -->
		<a-modal class="wx-pay-dialog" title="微信支付" v-model:visible="show.wxpay" width="400px" :footer="null">
			<div class="wx-pay-dialog-content">
				<div class="wx-pay-dialog-qrcode">
					<div class="qrcode-img">
						<img :src="show.wx_pay_qrcode" alt="">
					</div>
				</div>
				<!-- <div class="wx-pay-dialog-total">支付金额：{{form.amount_actual/100}}元</div> -->
				<div class="wx-pay-dialog-tag">请使用微信扫一扫，扫描二维码进行支付</div>
			</div>
			<div class="wx-pay-dialog-tag-1">支付完成前请不要关闭此窗口</div>
			<div style="text-align: center;">
				<a-space>
					<a-button @click="show.wxpay = false">不想支付了</a-button>
					<a-button type="primary" @click="checkMyPayd">我已支付</a-button>
				</a-space>
			</div>
		</a-modal>
    </div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import appModel from '@/api/saas/app.js'
import setModel from "@/api/saas/set.js"
import serviceModel from "@/api/saas/service.js"
import router from '@/router'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const active = ref(0)
        const _d = reactive({
            server:[],
            meal:[],
            config:null,
            form:{
                server_id:0,
                meal_id:0,
                logo:"",
                program_name:"",
                is_trail:0,     //是否为使用版本
                father_id:0,
                pay_type:"ali",
                is_sass:true,
            },
            time:5,
            show:{
                payMethod:false,
                wxpay:false,
                wx_pay_qrcode:"",
                order_sn:"",
                isCreate:true,
                showPay:true
            },
            programInfo:null,       //创建成功后的应用信息

        })

        const option = useRoute().query
        if( option.order_number ){
            active.value = 3
            let timer = setInterval(function(){
                if( _d.time <=0 ){
                    clearInterval(timer)
                    router.push("/shop/index")
                }else{
                    _d.time--
                }
            },1000)
        }

        setModel.getSiteSet(['app_is_open_try','app_try_days'],(res)=>{
            _d.config = res
        })
        serviceModel.getServerScheme(1,999,res=>_d.server = res.list)

        function selectServer(id){
            _d.form.father_id = id
            serviceModel.getBranch(id,1,res=>_d.meal = res)
            active.value = 1
        }

        function selectMeal(data){
            console.log('data',data);
            _d.form.server_id = data.server_id
            active.value = 2
            if( !data.discount_price || data.discount_price <= 0 ){
                if( !data.price || data.price <= 0 ){
                    _d.show.showPay = false
                }
            }


            if(  _d.config.app_is_open_try && _d.config.app_try_days && (data.pirce >0 || data.discount_price >0 )){
                //status === true 表示已经创建过试用版本了
                appModel.checkCreateProgram(data.server_id,res=>_d.show.isCreate = res.status ? false:true )
            }
        }

        function createShopNow(){
            let param = _d.form

            if( _d.config.app_is_open_try && _d.config.app_try_days ){
                param.is_trail = 1
            }

            _d.meal.forEach(item=>{
                if( item.server_id == param.server_id ){
                    if( item.discount_price == 0 && item.price == 0 ){
                        param.is_trail =0
                    }
                }
            })

            param.is_sass = true
            appModel.addOrEditProgram('add',param,res=>{
                active.value = 3
                _d.programInfo = res.result
                let timer = setInterval(function(){
                    if( _d.time <=0 ){
                        clearInterval(timer)
                        router.push("/shop/index")
                    }else{
                        _d.time--
                    }
                },1000)
            })
        }

        //用户购买
        function createShopOrder(){
            _d.show.payMethod = false
            appModel.createSaasProgramOrder(_d.form,res=>{
                _d.show.wxpay = true
				_d.show.wx_pay_qrcode = res.pay
                _d.show.order_sn = res.order_sn
            })
        }

        function checkMyPayd(){
            appModel.checkSassProgramOrder(_d.show.order_sn,()=>{
                _d.show.wxpay = false
                active.value = 3
                let timer = setInterval(function(){
                    if( _d.time <=0 ){
                        clearInterval(timer)
                        router.push("/shop/index")
                    }else{
                        _d.time--
                    }
                },1000)
            })
        }

        function toManager(type){
            if( type == -1 ){
                router.go(-1)
                return
            }

            if( type == 1 ){
                appModel.intoProgram(_d.programInfo.program_id)
            }
        }

        return{
            active,
            ...toRefs(_d),
            selectServer,
            selectMeal,
            createShopNow,
            createShopOrder,
            checkMyPayd,
            toManager
        }
    },
}
</script>
<style lang="scss" scoped>
.bg{
    background: #f0f2f5;
}
.si-con{
    background: #fff;
    padding: 24px;
}
.si-scheme{
    list-style: none;
    margin-top: 24px;

    >li{
        display: inline-block;
        border: 1px solid #f2f2f2;
        width: 180px;
        height: 240px;
        margin: 0 24px 24px 0;
        overflow: hidden;

        .top{
            width: 100%;
            text-align: center;
            background: #f2f2f2;
            min-height: 130px;
            padding-top: 32px;
        }
        .title{
            font-size: 16px;
            font-weight: bold;
        }
        .desc{
            width: 100%;
            padding: 0 12px;
            font-size: 12px;
        }
        .btm{
            width: 100%;
            text-align: center;
            margin-top: 24px;
        }
    }
}

.si-server{
    width: 100%;
    margin-top: 24px;
    text-align: center;

    >li{
        width: 240px;
        height: 320px;
        border: 1px solid #f2f1f1;
        list-style: none;
        display: inline-block;
        margin: 0 24px 24px 0;
        text-align: center;
        padding-top: 24px;
        overflow: hidden;
        position: relative;
    }
    .title{
        font-weight: bold;
        font-size: 20px;
    }
    .desc{
        font-size: 12px;
        color: #666;
    }

    .func{
        margin: 32px 0 24px 0;
        width: 100%;
        height: 56px;
        padding: 0 12px;
    }
    .price{
        font-size: 18px;
        line-height: 24px;
    }
    .now-price{
        padding: 0 8px;
        height: 20px;
        border: 1px solid red;
        color: red;
        font-size: 12px;
        display: inline-block;
        margin-top: 8px;
    }
    .btn{
        position: absolute;
        bottom: 22px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.si-farm{
    margin-top: 24px;
}
.si-complete{
    width: 100%;
    text-align: center;
    height: 300px;
    padding-top: 140px;
    color: #666;

    span{
        font-size: 24px;
        color: #333;
    }
}

.kd-paymethod{
    display: flex;

    &-li{
        width: 150px;
        height: 50px;
        border: 1px solid #e4e4e4;
        margin-right: 10px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        position: relative;
        .active-img{
            position: absolute;
            width: 24px;
            height: 20px;
            margin-top: 28px;
            right: 0;
        }
        .active-img-alipay{
            position: absolute;
            width: 24px;
            height: 20px;
            margin-top: 28px;
            right: 0;
        }
    }

    .kd-p-active{
        border: 2px solid #ff4544;
    }
}

.wx-pay-dialog{
    &-content{
        text-align: center;
        border-bottom: 1px dashed #e6e6e6;
        padding-bottom: 20px;
    }
    &-qrcode{
        width: 100%;
        height: 200px;
        padding: 10px;
        display: flex;
        justify-content: center;

        .qrcode-img{
            width: 160px;
            height: 160px;
            box-shadow: 1px 2px 10px #bdd8f1;
        }
        img{
            width: 150px;
            height: 150px;
        }
    }
    &-total{
        color: orange;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
    }
    &-tag{
        font-size: 12px;
        color: #868686;
        margin-top: 10px;
    }
    &-tag-1{
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #868686;
        height: 50px;
        line-height: 50px;
    }
}
</style>
